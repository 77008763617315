<template>
    <div class="page_catalogue">
        <template v-if="admin">
            <h2>CATALOGUE</h2>
            
            <br/>
            <vs-row>
                <vs-col vs-lg="3" vs-xs="12" vs-sm="6" v-for="cat in categorie" :key="cat">
                    <div class="container">
                        <vs-button class="w-full" 
                        :color="btnColor(cat)"
                        v-on:click="selectFiltre(cat)">{{ cat }}</vs-button>
                    </div><br/>
                </vs-col>
            </vs-row>
            <br/>
            <vs-table v-model="selected" pagination max-items="20" search :data="catalogue" @selected="editProduit" noDataText="Aucun résultat">

                <template slot="header">
                    <div class="dropdown-button-container">
                        <vs-button class="btnx" type="filled" v-on:click="newProduit()" icon-pack="feather" icon="icon-plus">Nouveau</vs-button>
                        <vs-dropdown>
                            <vs-button class="btn-drop cursor" type="filled" icon="menu"></vs-button>
                            <vs-dropdown-menu>
                                <vs-dropdown-item v-on:click="modifCategorie()" >Modifier catégorie</vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                </template>

                <!-- header table -->
                <template slot="thead">
                    <vs-th sort-key="produit">Nom</vs-th>
                    <vs-th sort-key="categorie">Catégorie</vs-th>
                    <vs-th sort-key="type">Type</vs-th>
                    <vs-th sort-key="tva">TVA %</vs-th>
                    <vs-th sort-key="ht">HT {{ devise.symbol }}</vs-th>
                    <vs-th sort-key="ttc">TTC {{ devise.symbol }}</vs-th>
                    <vs-th sort-key="day">Durée en jours</vs-th>
                    <vs-th sort-key="credit">Crédit</vs-th>
                    <vs-th sort-key="compte_produit">Compte Produit</vs-th>
                    <vs-th sort-key="compte_tva">Compte TVA</vs-th>
                    <!--TODO GROUPE-->
                </template>

                <!--Body table-->
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].produit">
                            <span>{{ data[indextr].produit }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].categorie">
                            <span>{{ data[indextr].categorie }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].type">
                            <span v-if=" data[indextr].type == 1" >Abonnement</span>
                            <span v-else>Classique</span>
                        </vs-td>
                        <vs-td :data="data[indextr].tva">
                            <span>{{ data[indextr].tva.toFixed(devise.nb_decimal) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].ht">
                            <span>{{ data[indextr].ht.toFixed(devise.nb_decimal) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].ttc">
                            <span>{{ data[indextr].ttc.toFixed(devise.nb_decimal) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].day">
                            <span>{{ data[indextr].day }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].credit">
                            <span>{{ data[indextr].credit }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].compte_produit">
                            <span>{{ data[indextr].compte_produit }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].compte_tva">
                            <span>{{ data[indextr].compte_tva }}</span>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            <AddCatalogue ref="addCatalogue" @refresh="refresh"/>
            <ChangeCatCatalogue ref="changeCat" />
        </template>
        <template v-else>
            <h2>Accés non autorisé.</h2>
        </template>
    </div>
</template>



<style lang="scss">
.page_catalogue{
    .dropdown-button-container {
        margin-right:5px;
        display: flex;
        align-items: center;

        .btnx {
            border-radius: 5px 0px 0px 5px;
        }
            .btnx2 {
            border-left: 1px solid rgba(255, 255, 255, .2);	
            border-radius: 0px 0px 0px 0px;
        }

        .btn-drop {
            border-radius: 0px 5px 5px 0px;
            border-left: 1px solid rgba(255, 255, 255, .2);
        }
    }
}
</style>

<script>

import Utils from '@/assets/utils/utils';
import autoRefresh from '@/assets/utils/autoRefresh'
import Catalogue  from '@/database/models/catalogue'
import Member from '@/database/models/member'

import vSelect from 'vue-select'
import AddCatalogue from '@/components/catalogue/add.vue'
import ChangeCatCatalogue from '@/components/catalogue/changeCat.vue'

export default {
    components: {
		vSelect,
        AddCatalogue,
        ChangeCatCatalogue
	},
    data(){
        return{
            selected:[],
            catalogue:[],
            catalogue_complet:[],

            categorie:[],
            filtre_save:'',
            admin:false,

            devise: {}
        }
	},
	mounted:function()
	{
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            //refresh
            this.getCatalogue()
        })
        autoRefresh.refresh()
        this.RoleAdmin();
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
        getCatalogue(){
            this.categorie = [];
            Catalogue.getTabAllMemory((list_cat)=>{
                this.catalogue = list_cat
                this.catalogue_complet = list_cat;
                
                //triage ABC
                this.catalogue.sort(function(a, b)
                {
                    var ca  = a.produit.toUpperCase();
                    var cb  = b.produit.toUpperCase();
                    return ('' + ca).localeCompare(''+cb);
                });

                //build categorie list
                for( var i=0; i<this.catalogue.length; i++)
                {
                    if(this.categorie.indexOf( this.catalogue[i].categorie) < 0)
                        this.categorie.push( this.catalogue[i].categorie );
                }
            })
        },
        refresh(){
            // console.log('refresh')
            this.getCatalogue()
        },
		newProduit(){
            
			this.$refs.addCatalogue.openPopup(undefined,()=>{
                this.getCatalogue();
				autoRefresh.refresh()
			})
        },
        editProduit( cat ){
            this.$refs.addCatalogue.openPopup( Utils.clone(cat), ()=>{
				autoRefresh.refresh()
			})
        },
        modifCategorie(){
            this.$refs.changeCat.openPopup(()=>{
				autoRefresh.refresh()
			})
        },
        
        selectFiltre( name )
        {
            if(this.filtre_save == name)
            {
                this.catalogue = this.catalogue_complet;
                this.filtre_save = '';
                return
            }
            this.filtre_save = name;

            this.catalogue = [];
            for( var i=0; i<this.catalogue_complet.length; i++)
            if(this.catalogue_complet[i].categorie == name )
                this.catalogue.push( this.catalogue_complet[i] );
        },
        btnColor( name )
        {
            //rgb(109, 173, 249)
            if(this.filtre_save == name)
				return 'success'
			return 'primary';
        },

        RoleAdmin()
        {
            this.admin = false;
            
            //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        }
    }
};

</script>